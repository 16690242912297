// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border-radius: 0 8px 8px 0;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  transition: width 0.3s ease;
  width: 50px;
  height: auto;
}

.sidebar:hover {
  width: 180px;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.nav-item .icon {
  min-width: 20px;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.nav-item .label {
  margin-left: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sidebar:hover .nav-item .label {
  opacity: 1;
}

.nav-item:hover {
  background-color: #f8f9fa;
}

.nav-item.active {
  background-color: white;
  color: #181c24;
  border-left: 3px solid #181c24;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,QAAQ;EACR,2BAA2B;EAC3B,iBAAiB;EACjB,0BAA0B;EAC1B,yCAAyC;EACzC,aAAa;EACb,gBAAgB;EAChB,2BAA2B;EAC3B,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,qBAAqB;EACrB,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,cAAc;EACd,8BAA8B;AAChC","sourcesContent":[".sidebar {\n  position: fixed;\n  left: 0;\n  top: 50%;\n  transform: translateY(-50%);\n  background: white;\n  border-radius: 0 8px 8px 0;\n  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  overflow: hidden;\n  transition: width 0.3s ease;\n  width: 50px;\n  height: auto;\n}\n\n.sidebar:hover {\n  width: 180px;\n}\n\n.sidebar-nav {\n  display: flex;\n  flex-direction: column;\n  padding: 8px 0;\n}\n\n.nav-item {\n  display: flex;\n  align-items: center;\n  padding: 10px 15px;\n  color: #333;\n  text-decoration: none;\n  transition: background-color 0.3s;\n  white-space: nowrap;\n}\n\n.nav-item .icon {\n  min-width: 20px;\n  display: flex;\n  justify-content: center;\n  font-size: 16px;\n}\n\n.nav-item .label {\n  margin-left: 10px;\n  opacity: 0;\n  transition: opacity 0.3s ease;\n}\n\n.sidebar:hover .nav-item .label {\n  opacity: 1;\n}\n\n.nav-item:hover {\n  background-color: #f8f9fa;\n}\n\n.nav-item.active {\n  background-color: white;\n  color: #181c24;\n  border-left: 3px solid #181c24;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
