import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../components/Modal';
import { checkAuth } from '../utils/auth';

const AddApplicationModal = ({ isOpen, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    host: '',
    application_type: 'web application',
    operation_type: 'black-box'
  });
  const [error, setError] = useState(null);
  const [urlExample, setUrlExample] = useState(null);
  const [urlSuggestion, setUrlSuggestion] = useState(null);
  const [urlDetails, setUrlDetails] = useState(null);
  const navigate = useNavigate();

  const validateUrl = (url) => {
    if (formData.application_type === 'web application') {
      const urlRegex = /^https?:\/\/(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+\/?$/;
      if (!urlRegex.test(url)) {
        setError('URL inválida');
        setUrlExample(`https://www.${url.replace(/^https?:\/\/(www\.)?/, '')}`);
        setUrlSuggestion({
          suggestion: 'A URL deve começar com http:// ou https://',
          examples: [
            'https://www.exemplo.com.br',
            'https://exemplo.com',
            'http://www.meusite.com.br'
          ]
        });
        return false;
      }
    }
    setError(null);
    setUrlExample(null);
    setUrlSuggestion(null);
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Limpa os erros quando muda o tipo de aplicação
    if (name === 'application_type') {
      setError(null);
      setUrlExample(null);
      setUrlSuggestion(null);
    }

    // Valida URL em tempo real apenas para aplicações web
    if (name === 'host' && formData.application_type === 'web application') {
      if (value) { // Só valida se tiver algum valor
        validateUrl(value);
      } else {
        setError(null);
        setUrlExample(null);
        setUrlSuggestion(null);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Valida antes de enviar
    if (formData.application_type === 'web application') {
      if (!validateUrl(formData.host)) {
        return; // Impede o envio se a URL for inválida
      }
    }

    try {
      const { isAuthenticated, companyUuid } = checkAuth();
      if (!isAuthenticated) {
        navigate('/auth/signin');
        return;
      }

      const response = await axios.post(`/api/applications/company/${companyUuid}`, formData);
      onAdd(response.data.application);
      onClose();
    } catch (error) {
      if (error.response?.data) {
        const { error: errorMessage, example, suggestion, examples, details, providedUrl } = error.response.data;
        setError(errorMessage);
        if (example) setUrlExample(example);
        if (suggestion) setUrlSuggestion({ suggestion, examples: examples || [] });
        if (details) setUrlDetails({ details, providedUrl });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal-content">
        <h2>Adicionar Nova Aplicação</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="host">URL da Aplicação</label>
            <input
              type="text"
              id="host"
              name="host"
              value={formData.host}
              onChange={handleChange}
              placeholder="https://www.exemplo.com.br"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="application_type">Tipo de Aplicação</label>
            <select
              id="application_type"
              name="application_type"
              value={formData.application_type}
              onChange={handleChange}
              required
            >
              <option value="web application">Aplicação Web</option>
              <option value="mobile application">Aplicação Mobile</option>
              <option value="api">API</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="operation_type">Tipo de Operação</label>
            <select
              id="operation_type"
              name="operation_type"
              value={formData.operation_type}
              onChange={handleChange}
              required
            >
              <option value="black-box">Black Box</option>
              <option value="white-box">White Box</option>
              <option value="gray-box">Gray Box</option>
            </select>
          </div>

          {error && (
            <div className="error-container">
              <div className="error-message">{error}</div>
              {urlExample && (
                <div className="url-example">
                  <p>Sugestão:</p>
                  <code>{urlExample}</code>
                </div>
              )}
              {urlSuggestion && (
                <div className="url-suggestions">
                  <p>Formato correto:</p>
                  <code>{urlSuggestion.suggestion}</code>
                  <p>Exemplos válidos:</p>
                  <ul>
                    {urlSuggestion.examples.map((example, index) => (
                      <li key={index}><code>{example}</code></li>
                    ))}
                  </ul>
                </div>
              )}
              {urlDetails && (
                <div className="url-details">
                  <p>Detalhes do erro:</p>
                  <ul>
                    {urlDetails.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                  <p>URL fornecida:</p>
                  <code>{urlDetails.providedUrl}</code>
                </div>
              )}
            </div>
          )}

          <div className="button-group">
            <button type="button" onClick={onClose}>Cancelar</button>
            <button type="submit">Adicionar</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddApplicationModal; 