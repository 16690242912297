// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer.footer {
  background-color: #181c24 !important;
  padding: 6px 24px;
  font-size: 11px;
  color: #ffffff !important;
  border-top: 1px solid #333333;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  height: 26px;
  line-height: 1.2;
  font-weight: normal;
}

footer.footer p {
  margin: 0;
  color: #ffffff;
  font-size: 11px;
  white-space: nowrap;
  font-weight: normal;
}

footer.footer .footer-links {
  display: flex;
  gap: 10px;
}

footer.footer .footer-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 11px;
  white-space: nowrap;
  font-weight: normal;
}

footer.footer .footer-links a:hover {
  color: #f1c40f;
} `, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,6BAA6B;EAC7B,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,2BAA2B;EAC3B,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["footer.footer {\n  background-color: #181c24 !important;\n  padding: 6px 24px;\n  font-size: 11px;\n  color: #ffffff !important;\n  border-top: 1px solid #333333;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  z-index: 100;\n  height: 26px;\n  line-height: 1.2;\n  font-weight: normal;\n}\n\nfooter.footer p {\n  margin: 0;\n  color: #ffffff;\n  font-size: 11px;\n  white-space: nowrap;\n  font-weight: normal;\n}\n\nfooter.footer .footer-links {\n  display: flex;\n  gap: 10px;\n}\n\nfooter.footer .footer-links a {\n  color: #ffffff;\n  text-decoration: none;\n  transition: color 0.3s ease;\n  font-size: 11px;\n  white-space: nowrap;\n  font-weight: normal;\n}\n\nfooter.footer .footer-links a:hover {\n  color: #f1c40f;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
