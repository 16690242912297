// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
  padding: 40px 20px;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 40px;
}

.profile-container h2 {
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.profile-content {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-group {
  padding: 20px;
  border-radius: 8px;
  background: #f8f9fa;
  transition: all 0.3s ease;
}

.info-group:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.info-group label {
  display: block;
  color: #6c757d;
  font-size: 0.9em;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.info-group span {
  display: block;
  color: #212529;
  font-size: 1.1em;
  font-weight: 500;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #6c757d;
  font-size: 1.1em;
} `, "",{"version":3,"sources":["webpack://./src/components/Profile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;EAC3B,yCAAyC;AAC3C;;AAEA;EACE,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".profile-container {\n  padding: 40px 20px;\n  max-width: 500px;\n  margin: 0 auto;\n  margin-top: 40px;\n}\n\n.profile-container h2 {\n  color: #333;\n  margin-bottom: 30px;\n  text-align: center;\n  font-size: 28px;\n  font-weight: 600;\n}\n\n.profile-content {\n  background: #fff;\n  border-radius: 12px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  padding: 30px;\n}\n\n.profile-info {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.info-group {\n  padding: 20px;\n  border-radius: 8px;\n  background: #f8f9fa;\n  transition: all 0.3s ease;\n}\n\n.info-group:hover {\n  transform: translateY(-2px);\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);\n}\n\n.info-group label {\n  display: block;\n  color: #6c757d;\n  font-size: 0.9em;\n  margin-bottom: 8px;\n  text-transform: uppercase;\n  letter-spacing: 0.5px;\n}\n\n.info-group span {\n  display: block;\n  color: #212529;\n  font-size: 1.1em;\n  font-weight: 500;\n}\n\n.loading {\n  text-align: center;\n  padding: 40px;\n  color: #6c757d;\n  font-size: 1.1em;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
