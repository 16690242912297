import React from 'react';
import '../styles/Modal.css';

export const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
}; 