import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../utils/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  FaChartLine, 
  FaFileAlt, 
  FaLightbulb 
} from 'react-icons/fa';
import Sidebar from './Sidebar';
import './Reports.css';
import { checkAuth } from '../utils/auth';
import { useLanguage } from '../LanguageContext';
import Footer from './Footer';

const MonitoringReports = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useLanguage();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedImpact, setSelectedImpact] = useState('');
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [applications, setApplications] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reports, setReports] = useState([]);
  const [activeItem, setActiveItem] = useState(translate('monitoring.reports'));
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isApplicationOpen, setIsApplicationOpen] = useState(false);
  const statusRef = useRef(null);
  const applicationRef = useRef(null);
  const reportsPerPage = 8;

  const monitoringItems = [
    {
      icon: <FaChartLine />,
      name: translate('monitoring.dashboard'),
      link: '/monitoring/dashboard'
    },
    {
      icon: <FaFileAlt />,
      name: translate('monitoring.reports'),
      link: '/monitoring/reports'
    },
    {
      icon: <FaLightbulb />,
      name: translate('monitoring.insights'),
      link: '/monitoring/insights'
    }
  ];

  const impactOptions = [
    { 
      value: '', 
      label: (
        <div className="impact-option">
          <i className="fas fa-exclamation-triangle icon all" />
          {translate('monitoring.filters.all')}
        </div>
      )
    },
    {
      value: '9-10',
      label: (
        <div className="impact-option">
          <i className="fas fa-exclamation-triangle icon critical" />
          {translate('reports.filters.impact_level.critical')}
        </div>
      )
    },
    {
      value: '7-8.9',
      label: (
        <div className="impact-option">
          <i className="fas fa-exclamation-triangle icon high" />
          {translate('reports.filters.impact_level.high')}
        </div>
      )
    },
    {
      value: '4-6.9',
      label: (
        <div className="impact-option">
          <i className="fas fa-exclamation-triangle icon medium" />
          {translate('reports.filters.impact_level.medium')}
        </div>
      )
    },
    {
      value: '0-3.9',
      label: (
        <div className="impact-option">
          <i className="fas fa-exclamation-triangle icon low" />
          {translate('reports.filters.impact_level.low')}
        </div>
      )
    }
  ];

  const statusOptions = [
    { value: 'New', icon: 'fas fa-star' },
    { value: 'Under Review', icon: 'fas fa-search' },
    { value: 'Confirmed', icon: 'fas fa-check-circle' },
    { value: 'Awaiting Fix', icon: 'fas fa-tools' },
    { value: 'In Mitigation', icon: 'fas fa-shield-alt' },
    { value: 'Awaiting Retest', icon: 'fas fa-sync' },
    { value: 'Retest Done', icon: 'fas fa-clipboard-check' },
    { value: 'Fixed', icon: 'fas fa-check-double' },
    { value: 'Closed', icon: 'fas fa-lock' }
  ];

  const getStatusIcon = (status) => {
    const option = statusOptions.find(opt => opt.value === status);
    return option ? option.icon : 'fas fa-question';
  };

  const getFaviconUrl = (host) => {
    if (!host) return null;
    return `https://www.google.com/s2/favicons?domain=${host}&sz=32`;
  };

  const fetchApplications = useCallback(async () => {
    try {
      const { isAuthenticated, companyUuid } = checkAuth();
      if (!isAuthenticated) {
        navigate('/auth/signin');
        return;
      }

      const response = await axios.get(`/applications/company/${companyUuid}`);
      console.log('Aplicações carregadas:', response.data);
      
      // Adiciona a opção "Todos" no início do array
      const allOption = {
        value: '',
        label: translate('monitoring.filters.all'),
        host: '',
        isAllOption: true
      };
      
      const appOptions = response.data.applications.map(app => ({
        value: app.id,
        label: app.host,
        host: app.host,
        isAllOption: false
      }));

      setApplications([allOption, ...appOptions]);
    } catch (error) {
      console.error('Erro ao buscar aplicações:', error);
    }
  }, [navigate, translate]);

  const handleSearch = useCallback(async () => {
    try {
      const { isAuthenticated, companyUuid, userType } = checkAuth();

      console.log('\n🔍 Debug handleSearch:');
      console.log('👤 Tipo de usuário:', userType);
      console.log('🏢 Company UUID:', companyUuid);
      console.log('🔐 Autenticado:', isAuthenticated);

      if (!isAuthenticated) {
        console.log('❌ Usuário não autenticado');
        navigate('/auth/signin');
        return;
      }

      let queryParams = new URLSearchParams();
      
      queryParams.append('service', 'Monitoring');
      
      if (startDate) {
        queryParams.append('startDate', new Date(startDate).toISOString());
      }
      if (endDate) {
        queryParams.append('endDate', new Date(endDate).toISOString());
      }
      if (selectedImpact) {
        queryParams.append('impact', selectedImpact);
      }
      if (selectedStatus) {
        queryParams.append('status', selectedStatus);
      }
      if (selectedType) {
        queryParams.append('type', selectedType);
      }
      if (selectedApplication) {
        queryParams.append('applicationId', selectedApplication.value);
      }

      const response = await axios.get(
        `/reports/company/${companyUuid}?${queryParams.toString()}`
      );

      console.log('📊 Resposta da API:', response.data);
      console.log('📝 Total de relatórios:', response.data.reports?.length);
      console.log('🔍 Amostra de relatórios:', response.data.reports?.slice(0, 2));

      if (response.data) {
        setReports(Array.isArray(response.data) ? response.data : response.data.reports || []);
      }
    } catch (error) {
      console.error('❌ Erro ao buscar relatórios:', error);
      if (error.response?.status === 401) {
        navigate('/auth/signin');
      }
    }
  }, [navigate, startDate, endDate, selectedImpact, selectedStatus, selectedType, selectedApplication]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    fetchApplications();
  }, [fetchApplications]);

  const handleRowClick = (reportId) => {
    const report = reports.find(r => r.id === reportId);
    if (report) {
      const reportDetails = {
        ...report,
        type: report.type,
        application: report.application ? {
          id: report.application.id,
          name: report.application.host,
          host: report.application.host,
          type: report.application.application_type,
          favicon: getFaviconUrl(report.application.host)
        } : null
      };
      navigate(`/monitoring/reports/${reportId}`, { state: { report: reportDetails } });
    }
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const { isAuthenticated, companyUuid } = checkAuth();

        if (!isAuthenticated) {
          console.log('Usuário não autenticado');
          navigate('/auth/signin');
          return;
        }

        const response = await axios.get(
          `/reports/company/${companyUuid}?service=Monitoring`
        );

        setReports(Array.isArray(response.data) ? response.data : response.data.reports || []);
      } catch (error) {
        console.error('Erro ao buscar relatórios:', error);
        if (error.response?.status === 401) {
          navigate('/auth/signin');
        }
        setReports([]);
      }
    };

    fetchReports();
  }, [navigate]);

  // Funções de filtragem e paginação
  const filteredReports = Array.isArray(reports) ? reports.filter(report => {
    if (selectedImpact) {
      const [min, max] = selectedImpact.split('-').map(Number);
      const impact = parseFloat(report.impact);
      if (!(impact >= min && impact <= max)) return false;
    }
    return (
      (selectedType ? report.type === selectedType : true) &&
      (selectedStatus ? report.status === selectedStatus : true)
    );
  }) : [];

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(indexOfFirstReport, indexOfLastReport);
  const totalPages = Math.ceil(filteredReports.length / reportsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Função para determinar criticidade
  const getCriticality = (impact) => {
    const numericImpact = parseFloat(impact);
    if (numericImpact >= 9.0 && numericImpact <= 10.0) return "Critical";
    if (numericImpact >= 7.0 && numericImpact < 9.0) return "High";
    if (numericImpact >= 4.0 && numericImpact < 7.0) return "Medium";
    return "Low";
  };

  const getCriticalityIcon = (impact) => {
    const criticality = getCriticality(impact).toLowerCase();
    return (
      <div className={`impact-option`}>
        <i className={`fas fa-exclamation-triangle icon ${criticality}`} />
        <span>{translate(`reports.filters.impact_level.${criticality}`)}</span>
      </div>
    );
  };

  useEffect(() => {
    if (location.pathname.includes('/monitoring/reports')) {
      setActiveItem('Relatórios');
    }
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (statusRef.current && !statusRef.current.contains(event.target)) {
        setIsStatusOpen(false);
      }
      if (applicationRef.current && !applicationRef.current.contains(event.target)) {
        setIsApplicationOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Renderização do componente
  return (
    <div className="monitoring-container">
      <Sidebar 
        items={monitoringItems} 
        activeItem={activeItem}
      />
      <div className="content">
        <div className="reports-container">
          <h2 style={{ textAlign: 'left', marginLeft: '20px' }}>{translate('labels.vulnerability_reports')}</h2>
          
          <div className="date-search">
            <label htmlFor="start-date">{translate('common.from')}:</label>
            <input 
              type="date" 
              id="start-date" 
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label htmlFor="end-date">{translate('common.to')}:</label>
            <input 
              type="date" 
              id="end-date" 
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button onClick={handleSearch}>{translate('common.search')}</button>
          </div>

          <div className="filters" ref={statusRef}>
            <div className="filter">
              <label htmlFor="impact-select">{translate('monitoring.filters.impact_level')}:</label>
              <select
                id="impact-select"
                value={selectedImpact}
                onChange={(e) => setSelectedImpact(e.target.value)}
              >
                {impactOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>

            <div className="filter">
              <label htmlFor="status-select">{translate('monitoring.filters.status')}:</label>
              <div className="custom-status-select">
                <div 
                  className="status-selected"
                  onClick={() => setIsStatusOpen(!isStatusOpen)}
                >
                  {selectedStatus ? (
                    <div className={`status-option ${selectedStatus.toLowerCase().replace(/ /g, '-')}`}>
                      <i className={getStatusIcon(selectedStatus)}></i>
                      <span>{translate(`reports.filters.status.${selectedStatus.toLowerCase().replace(/ /g, '_')}`)}</span>
                    </div>
                  ) : (
                    <div className="status-option all">
                      <i className="fas fa-globe"></i>
                      <span>{translate('monitoring.filters.all')}</span>
                    </div>
                  )}
                </div>
                {isStatusOpen && (
                  <div className="status-options">
                    <div
                      className="status-option all"
                      onClick={() => {
                        setSelectedStatus('');
                        setIsStatusOpen(false);
                      }}
                    >
                      <i className="fas fa-globe"></i>
                      <span>{translate('monitoring.filters.all')}</span>
                    </div>
                    {statusOptions.map(status => (
                      <div
                        key={status.value}
                        className={`status-option ${status.value.toLowerCase().replace(/ /g, '-')}`}
                        onClick={() => {
                          setSelectedStatus(status.value);
                          setIsStatusOpen(false);
                        }}
                      >
                        <i className={status.icon}></i>
                        <span>{translate(`reports.filters.status.${status.value.toLowerCase().replace(/ /g, '_')}`)}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="filter">
              <label htmlFor="type-select">{translate('monitoring.filters.category')}:</label>
              <select
                id="type-select"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <option value="">{translate('monitoring.filters.all')}</option>
                <option value="Broken Access Control">{translate('reports.filters.category.broken_access_control')}</option>
                <option value="Cryptographic Failures">{translate('reports.filters.category.cryptographic_failures')}</option>
                <option value="Injection">{translate('reports.filters.category.injection')}</option>
                <option value="Cross-Site Scripting (XSS)">{translate('reports.filters.category.xss')}</option>
                <option value="Insecure Design">{translate('reports.filters.category.insecure_design')}</option>
                <option value="Security Misconfiguration">{translate('reports.filters.category.security_misconfiguration')}</option>
                <option value="Vulnerable and Outdated Components">{translate('reports.filters.category.vulnerable_outdated_components')}</option>
                <option value="Identification and Authentication Failures">{translate('reports.filters.category.identification_authentication_failures')}</option>
                <option value="Software and Data Integrity Failures">{translate('reports.filters.category.software_data_integrity_failures')}</option>
                <option value="Security Logging and Monitoring Failures">{translate('reports.filters.category.security_logging_monitoring_failures')}</option>
                <option value="Server-Side Request Forgery (SSRF)">{translate('reports.filters.category.ssrf')}</option>
                <option value="Other">{translate('reports.filters.category.other')}</option>
              </select>
            </div>

            <div className="filter">
              <label>{translate('monitoring.filters.application')}</label>
              <div className="select-container" ref={applicationRef}>
                <div 
                  className={`custom-select ${selectedApplication ? 'selected' : ''}`}
                  onClick={() => setIsApplicationOpen(!isApplicationOpen)}
                >
                  {selectedApplication ? (
                    <div className="selected-option">
                      {selectedApplication.isAllOption ? (
                        <>
                          <i className="fas fa-globe" />
                          <span>{translate('monitoring.filters.all')}</span>
                        </>
                      ) : (
                        <>
                          <img
                            src={getFaviconUrl(selectedApplication.host)}
                            alt=""
                            style={{ width: '16px', height: '16px' }}
                            onError={(e) => e.target.style.display = 'none'}
                          />
                          <span>{selectedApplication.label}</span>
                        </>
                      )}
                    </div>
                  ) : (
                    <span className="placeholder">{translate('monitoring.filters.select_application')}</span>
                  )}
                  <i className={`fas fa-chevron-${isApplicationOpen ? 'up' : 'down'}`} />
                </div>
                {isApplicationOpen && (
                  <div className="options-dropdown">
                    {applications.map((app, index) => (
                      <div
                        key={index}
                        className={`option ${selectedApplication?.value === app.value ? 'selected' : ''}`}
                        onClick={() => {
                          setSelectedApplication(app);
                          setIsApplicationOpen(false);
                          handleSearch();
                        }}
                      >
                        {app.isAllOption ? (
                          <>
                            <i className="fas fa-globe" />
                            <span>{translate('monitoring.filters.all')}</span>
                          </>
                        ) : (
                          <>
                            <img
                              src={getFaviconUrl(app.host)}
                              alt=""
                              style={{ width: '16px', height: '16px' }}
                              onError={(e) => e.target.style.display = 'none'}
                            />
                            <span>{app.label}</span>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <section className="reports-list">
            <table>
              <thead>
                <tr>
                  <th>{translate('monitoring.table.report_name')}</th>
                  <th>{translate('monitoring.table.application')}</th>
                  <th>{translate('monitoring.table.date')}</th>
                  <th>{translate('monitoring.table.type')}</th>
                  <th>{translate('monitoring.table.status')}</th>
                  <th>{translate('monitoring.table.impact')}</th>
                </tr>
              </thead>
              <tbody>
                {currentReports.map((report, index) => (
                  <tr key={index} onClick={() => handleRowClick(report.id)}>
                    <td style={{ textAlign: 'center' }}>{report.name}</td>
                    <td>
                      {report.application && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                          <img
                            src={getFaviconUrl(report.application.host)}
                            alt=""
                            style={{ width: '16px', height: '16px' }}
                            onError={(e) => e.target.style.display = 'none'}
                          />
                          <span>{report.application.host}</span>
                        </div>
                      )}
                    </td>
                    <td>{report?.created_at ? new Date(report.created_at).toLocaleDateString('pt-BR') : 'Data não disponível'}</td>
                    <td>{report.type}</td>
                    <td>
                      <div className={`status-display ${report.status.toLowerCase().replace(/ /g, '-')}`}>
                        <i className={getStatusIcon(report.status)}></i>
                        <span>{translate(`reports.filters.status.${report.status.toLowerCase().replace(/ /g, '_')}`)}</span>
                      </div>
                    </td>
                    <td>
                      {getCriticalityIcon(report.impact)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="pagination">
              <button 
                onClick={() => handlePageChange(currentPage - 1)} 
                disabled={currentPage === 1}
              >
                &lt;
              </button>
              <span>{currentPage} de {totalPages}</span>
              <button 
                onClick={() => handlePageChange(currentPage + 1)} 
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MonitoringReports; 